.poster-container {
  direction: ltr;
  background-color: #1e5694;
  width: 700px;
  height: 700px;
  background-image: url("https://files.armancnc.com/api/products/proxy-image?imageUrl=http://armancnc.com/wp-content/uploads/2024/03/armancnc-footer1_125243.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position-y: bottom;

  .poster-title {
    font-family: "DanaBold" !important;
    width: max-content;
    background-color: #ff0103;
    padding: 10px 45px;
    margin: auto;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    font-size: 27px;
    color: #fff;
    direction: rtl;

    max-width: 530px;
    word-break: break-word;
    text-align: center;
  }
  .arman-id {
    width: max-content;
    position: absolute;
    background-color: #ffffff9c;
    padding: 5px 10px;
    border-radius: 10px;
    left: 150px;
    top: 480px;
  }
  .poster-image {
    border-top-right-radius: 30px;
    border-bottom-right-radius: 30px;
    height: 400px;
    max-width: 420px;
  }

  .poster-attrs {
    width: 100%;
    border-collapse: collapse;
    height: max-content;
    margin: 0 15px;

    tr {
      height: 45px;
    }
    tr:first-child td:last-child {
      border-top-right-radius: 30px;
    }

    tr:first-child td:first-child {
      border-top-left-radius: 30px;
    }

    tr:last-child td:last-child {
      border-bottom-right-radius: 30px;
    }

    tr:last-child td:first-child {
      border-bottom-left-radius: 30px;
    }

    td {
      direction: rtl;
      background-color: #fff;
      text-align: center;
      border: 3px solid #1e5694;
      padding: 8px;
      font-size: 20px;
    }
  }
}

.body {
  margin-top: 60px;
  display: flex;
  align-items: center;
}

.poster-btn {
  background-color: #e61d30;
  border-color: #e61d30;
  color: #fff;

  &:hover {
    background-color: #ca1426;
    border-color: #ca1426;
    color: #fff;
  }
  &:disabled {
    background-color: #f1f1f1;
    border-color: #ccc;
    color: #999;
    cursor: not-allowed;
  }
  .footer-image {
    width: 100%;
    position: relative;
    bottom: -87px;
  }
}
