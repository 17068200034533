// @font-face {
//   font-family: "Dana";
//   src: url("../../assets/fonts/dana-medium.ttf") format("woff2");
// }

.label-container {
  width: 10cm;
  height: 10cm;
  text-align: right;
  direction: rtl;

  .section {
    border: 1px solid #000;
    h2,
    h3,
    h4 {
      margin: 0;
      font-family: "Dana" !important; 
    }
    padding: 10px;

    &.section-one {
      .flex-section {
        display: flex;
        justify-content: space-between;
        gap: 8px;

        .brand-title {
          margin: 0;
          font-size: 23px;
          text-align: center;
        }
        .arman-sku {
          margin: 0;
          margin-top: 5px;
          font-size: 40px;
          text-align: center;
        }
      }
      .title-container {
        h4 {
          margin: 0;
          text-align: center;
          font-size: 21px;
        }
      }
    }
    &.section-two {
      text-align: center;
    }
    &.section-three {
      text-align: center;
    }
  }
}
