.shipping-container {
  width: 80%;
  margin: auto;
  border: 1px solid #e0e0e0;
  font-family: Arial, sans-serif;
  direction: rtl;
  text-align: right;
}

.shipping-header {
  display: flex;
  gap: 15px;
  margin-bottom: 20px;
  justify-content: space-between;
  border-bottom: 1px solid #e0e0e0;
  padding: 20px;
}

.shipping-title {
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 10px;
}

.shipping-info {
  font-size: 16px;
  width: 250px;
  line-height: 30px;
}

.shipping-number {
  margin-top: 10px;
}

.status {
  padding: 5px 10px;
  border-radius: 5px;
  margin-right: 5px;

  &:not(.status-registered) {
    color: #252525;
    border: 1px solid #000;
  }
}

.status-registered {
  color: #fff;
  background-color: #4caf50;
}

.shipping-brand {
  img {
    margin-bottom: 5px;
  }
  text-align: center;
  border-bottom: 1px solid #e0e0e0;
  padding: 10px 0;
}

.shipping-table {
  width: 100%;
  border-collapse: collapse;

  &.shipping-details {
    width: 50%;
    margin: auto;
    margin-top: 20px;
  }
}

.shipping-table th,
.shipping-table td {
  border: 1px solid #e0e0e0;
  padding: 10px;
  text-align: center;
}

.shipping-body {
  padding: 20px;
}

@media only print {
  body.shipping {
    background-image: none;
  }
  .shipping-container {
    width: 100%;
  }
}
