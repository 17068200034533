body.gallery {
  overflow: scroll;
}
.gallery-container {
  padding: 1rem;
  direction: rtl;
  padding-top: 4rem;
  padding-bottom: 4rem;
  .btn {
    background-color: #fe0301;
    border: 0;
    color: #fff;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;

    &.disabled,
    &:disabled {
      background-color: #e1e1e1;
      cursor: not-allowed;
      color: #313131;
      opacity: 0.5;
    }
  }
  .back-btn {
    position: absolute;
    top: 0;
    left: 0;
    margin: 15px;
  }

  .image-container {
    display: inline-block;
    margin-left: 15px;
    position: relative;

    &.preview {
      display: inline-block;
    }
    .cover {
      position: absolute;
      background-color: #e1e1e196;
      color: #313131;
      padding: 5px 10px;
      border-bottom-left-radius: 10px;
      border-top-right-radius: 5px;
    }
    .cover-action {
      position: absolute;
      bottom: 8px;
      left: 0;
      button {
        border: 0;
        background-color: #fe0101b1;
        color: #fff;
        padding: 0;
        height: 30px;
        width: 30px;
        border-radius: 5px;
        border-top-left-radius: 0;
        border-bottom-right-radius: 0;
        font-size: 23px;

        &.texted {
          font-size: 13px;
          width: max-content;
          padding: 0 10px;
        }
      }
    }
  }
  .image-item {
    width: 200px;
    height: 200px;
    border-radius: 5px;
    box-shadow: -4px 2px 17px -3px rgba(0, 0, 0, 0.75);

    &.main {
      width: 280px;
      height: 280px;
    }
  }
  .container-title {
    margin-bottom: 0;
  }

  #main-image-upload,
  #other-images-upload {
    display: none;
  }
  .flex {
    display: flex;
    align-items: center;
    gap: 10px;
    .container-title {
      margin-bottom: 15px;
    }
    .btn {
      padding: 5px 10px;
      font-size: 15px;
    }
  }

  .hr {
    border-color: #dddddd;
  }

  .confirm-btn {
    background-color: #1965cf;
    border: 0;
    color: #fff;
    padding: 10px 20px;
    width: calc(100% - 30px);
    border-radius: 5px;
    cursor: pointer;
    position: fixed;
    bottom: 0;
    right: 0;
    margin: 15px;
  }
}

@media only screen and (max-width: 768px) {
  .image-item {
    width: 100px !important;
    height: 100px !important;

    &.main {
      width: 200px !important;
      height: 200px !important;
    }
  }
}
