.inventory-products-table {
  border-collapse: collapse;
  width: 100%;
  direction: rtl;

  th {
    background-color: #e6e6e6;
  }

  th,
  td {
    border: 1px solid #000;
    padding: 5px 10px;

    &#product-id {
      transform: rotate(90deg);
      padding: 0;
      width: 50px;
      color: #b60d0d;
    }
    &#product-img {
      text-align: center;
      width: 150px;

      img {
        width: 140px;
      }
    }
    &#product-name {
      width: 250px;
    }
    &#product-info {
      width: 300px;
    }
  }
}

.page-container {
  padding-top: 200px;
  padding-left: 50px;
  padding-right: 50px;
  page-break-after: always;
}

.force-break {
  page-break-before: always;
}

.page-header-text {
  display: none;
}

body {
  background-image: url("../../assets/images/inventory/background-page.png");
  background-size: contain;
}

@media only print {
  .page-header-text {
    display: flex;
    justify-content: space-around;
    position: absolute;
    width: 100%;
    top: 120px;
    direction: rtl;
  }
}

@media only screen {
  .page-container {
    margin: auto;
    width: 210mm;
    height: 270mm;
    background-image: url("../../assets/images/inventory/background-page.png");
    background-size: cover;
    box-shadow: 10px 0px 25px -7px rgba(0, 0, 0, 0.75);
    margin-bottom: 10px;
  }

  body {
    background-image: none;
  }

  .page-header-text {
    top: 120px;
    margin: auto;
  }

  .print-pages {
    height: 100vh;
    overflow-y: scroll;
  }
}
