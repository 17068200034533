@font-face {
  font-family: "Dana";
  src: url("../fonts/dana-medium.ttf") format("woff2");
}
@font-face {
  font-family: "DanaBold";
  src: url("../fonts/dana-bold.ttf") format("woff2");
}

*:not(.--prevent-font):not(.--prevent-font *) {
  font-family: "Dana";
}
.--prevent-font,
.--prevent-font * {
  font-family: initial !important;
}

body,
html {
  margin: 0 !important;
}

.main-loader {
  display: flex;
  padding-top: 20vh;
  justify-content: center;
  background-color: #000000f0;
  height: 100vh;
  align-content: center;
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  z-index: 5;
}

.loader-helper {
  color: #ac2727;
  z-index: 6;
  position: absolute;
  width: 100%;
  text-align: center;
  margin-top: 40vh;
  direction: rtl;
}

body {
  overflow: hidden;
}

a {
  text-decoration: none;
  color: rgb(0, 99, 197);
}

@media print {
  .main-loader-container {
    display: none;
  }
}

// @media print {
//   @page {
//     size: A4;
//   }
// }
