.poster-n-container {
  direction: rtl;
  width: 700px;
  height: 700px;
  position: relative;
  overflow: hidden; /* Hide content that overflows the parent */
  background-image: url("../../assets/images/poster/poster-background.png");
  &.details {
    background-color: #b8b5b5;
  }
  h1 {
    margin: 0;
  }
  .sku-container {
    position: absolute;
    background-color: #ff0303c6;
    border-radius: 5px;
    padding: 5px 10px;
    color: #fff;
    bottom: 100px;
    right: 20px;
  }

  .title-container {
    height: 250px;
    background: rgb(18, 118, 170);
    background: linear-gradient(
      180deg,
      rgba(18, 118, 170, 1) 16%,
      rgba(18, 118, 170, 0.7763480392156863) 46%,
      rgba(18, 118, 170, 0.37298669467787116) 69%,
      rgba(18, 118, 170, 0.22172619047619047) 79%,
      rgba(18, 118, 170, 0.12648809523809523) 93%,
      rgba(18, 118, 170, 0) 95%
    );
    display: flex;
    align-items: center;
    justify-content: space-around;
    position: relative;

    h1 {
      margin: 0;
      color: #fff;
      font-size: 40px;
      margin-top: -50px;
    }

    &.long {
      h1 {
        font-size: 30px;
      }
    }
  }

  .heder.long {
    h1 {
      font-size: 30px;
    }
  }
  .poster-image {
    position: absolute;
    min-width: 700px;
    max-width: 850px;
    height: 700px;
    z-index: -1;
  }

  .details-table {
    border: 3px dashed #e3dede;
    width: 600px;
    margin: auto;
    margin-top: 50px;
    padding: 4px;
    border-radius: 30px;

    .header {
      box-shadow: 0px 0px 38px -3px rgba(0, 0, 0, 0.394);
      border-top-left-radius: 25px;
      border-top-right-radius: 25px;
      background-color: red;

      h1 {
        color: #fff;
        text-align: center;
        padding-top: 7px;
        margin-bottom: 5px;
      }
    }

    .row {
      padding: 3px 0;
      display: flex;
      gap: 10px;
      position: relative;

      .row-number {
        position: absolute;
        background-color: #fff;
        width: 20px;
        height: 20px;
        color: red;
        border-radius: 5px;
        right: -10px;
        box-shadow: 0px 0px 30px -3px rgba(0, 0, 0, 0.713);
      }

      .content {
        box-shadow: 0px 0px 38px -3px rgba(0, 0, 0, 0.394);
        background-color: #2873ba;
        color: #fff;
        width: 50%;
        padding: 10px;
        text-align: center;
      }

      &:last-child .content {
        &:first-child {
          border-bottom-right-radius: 25px;
        }
        &:last-child {
          border-bottom-left-radius: 25px;
        }
      }
    }
  }
}
