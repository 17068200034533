.page-container-catalogue {
  padding-top: 170px;
  padding-left: 10px;
  padding-right: 10px;
  page-break-after: always;
  position: relative;

  .page-header-text {
    display: flex;
    justify-content: flex-start;
    position: absolute;
    width: 100%;
    top: 70px;
    direction: rtl;
    right: 3%;

    #category-title,
    #sku {
      margin: 0;
    }
    #category-title {
      margin-top: 10px;
      font-size: 27px;

      &.long-title {
        font-size: 22px;
      }
    }
    #sku {
      font-size: 15px;
      margin-top: 8px;
      color: #ff0303;
    }
  }

  .columns-container {
    display: flex;
    gap: 5px;
    width: 100%;
    height: 85%;

    .column {
      width: 100%;

      .large-image-container {
        text-align: center;
        height: 280px !important;
        margin-bottom: 5px;

        img {
          width: 100%;
          max-height: 100%;
        }
      }
      .medium-images-container {
        display: flex;
        flex-direction: column;
        gap: 5px;
        .medium-image-container {
          text-align: center;
          height: 150px;
          float: right;
          img {
            height: 100%;
            width: 330px;
            float: right;
          }
        }
      }

      .info-table-container {
        // transform: scale(0.6);
        position: relative;

        .info-table {
          // right: -30px;
          height: 550px;
          width: 95%;
          position: absolute;
          padding: 20px 10px;
          background-color: #f1f0f8;
          border-radius: 10px;

          table {
            width: 100%;
            border-collapse: collapse;
            font-size: 14px;
            tr {
              border-bottom: 1px solid #000;
              &:last-child {
                border-bottom: none;
              }

              &:first-child {
                position: relative;
                color: #ff0303;
              }

              #value {
                text-align: left;

                &.small-value {
                  direction: rtl;
                  font-size: 12px;
                  text-align: right;
                  max-width: 250px;
                  word-break: break-all;
                }
              }
              #label {
                text-align: right;
                width: 180px;
              }
            }
          }
        }
      }
    }
  }
}

.force-break {
  page-break-before: always;
}

body {
  //   background-image: url("../../assets/images/catalogue/background-page.jpg");
  background-size: contain !important;
}

@media only print {
  .export-btn {
    display: none;
  }
  body.catalogue {
    background-image: url("../../assets/images/catalogue/background-page.jpg");
    background-size: contain !important;

    &.catalogue-single {
      background-size: cover !important;
    }
  }
}

@media only screen {
  .page-container-catalogue {
    margin: auto;
    width: 210mm;
    height: 270mm;
    background-image: url("../../assets/images/catalogue/background-page.jpg");
    background-size: cover;
    box-shadow: 10px 0px 25px -7px rgba(0, 0, 0, 0.75);
    margin-bottom: 10px;
  }

  body {
    background-image: none;
  }

  .print-pages {
    height: 100vh;
    overflow-y: scroll;
  }
}

.export-btn {
  border: 1px solid #ff0303;
  border-radius: 2px;
  background-color: transparent;
  color: #ff0303;
  padding: 5px 15px;
  margin: 5px;
}
